import React, { useEffect } from 'react';
import AuthenticatedContext from '../../auth/AuthenticatedContext';
import TenantPermissionProvider from '../../services/TenantPermissionProvider';
import HandlerLayout from './HandlerLayout';
import LoadingSuspense from '../../components/LoadingSuspense';
import RouteWrapper from '../../components/RouteWrapper';
import TenantPermissions from '../../enums/TenantPermissions';
import useLogout from '../../auth/useLogout';
import { useUserHubConnection } from '../../services/UserHubConnectionProvider';
import useUserId from '../../hooks/useUserId';

const PolicyViewer = React.lazy(() => import('../policy-viewer/PolicyViewer'));
const ClaimStatusPage = React.lazy(() => import('./claim-status-page/ClaimStatusPage'));
const MakeAClaim = React.lazy(() => import('./make-a-claim-page/MakeAClaimPage'));

function HandlerRoutesWrapper() {
  const logout = useLogout();
  const userHubConnection = useUserHubConnection();
  const userId = useUserId();

  useEffect(() => {
    if (userId) {
      userHubConnection.on('UserDisabled', (user) => {
        if (userId === user.id) {
          logout();
        }
      });
    }
  }, [userHubConnection, logout, userId]);

  return (
    <TenantPermissionProvider permission={TenantPermissions.Handler}>
      <AuthenticatedContext>
          <HandlerLayout>
            <LoadingSuspense>
            <RouteWrapper exact path='/' component={PolicyViewer} />
            <RouteWrapper exact path='/claim-status' component={ClaimStatusPage} />
            <RouteWrapper exact path='/make-a-claim' component={MakeAClaim} />
            </LoadingSuspense>
          </HandlerLayout>
      </AuthenticatedContext>
    </TenantPermissionProvider>
  );
}

export default HandlerRoutesWrapper;
