import axios from 'axios';

export default async function loadTenantManifestAssets() {
  const head = document.querySelector('head');
  const response = await fetch('api/app-config');
  const result = await response.json();
  const responseClientAssetsUrl = await axios
    .get(`${result.webApiUrl}/api/client-config/client-assets-url`)
    .then((res) => {
      return res;
    });
  const clientAssetsUrl = responseClientAssetsUrl.data;
  getTenantTabTitle(result.webApiUrl); 
  const favIcon = createTenantFavIcon(clientAssetsUrl);
  const manifest = createTenantManifest(clientAssetsUrl);
  head.append(manifest, favIcon);
}

function createTenantManifest(clientAssets) {
  const link = document.createElement('link');
  link.href = `${clientAssets}/manifest.json`;
  link.rel = 'manifest';
  link.crossOrigin = 'use-credentials';
  return link;
}

function createTenantFavIcon(clientAssets) {
  const link = document.createElement('link');
  link.href = `${clientAssets}/favicon.ico`;
  link.rel = 'icon';
  return link;
}

async function getTenantTabTitle(webApiUrl) {
  // commented out as it's currently hardcoded for Handler
  // const tabTitle = await axios.get(`${webApiUrl}/api/client-config/client-tab-title`).then((res) => {
  //   return res;
  // });

  var title = document.querySelector('title');
  title.innerHTML = 'Client Portal';
}
